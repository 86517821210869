<template>
    <div>
        <b-row>
            <b-col md="6">
                <b-card>
                    <b-card-header>
                        <h4 class="text-primary"># 7236487238476</h4>
                    </b-card-header>
                    <hr>
                    <b-card-body>
                        <h5>Name: AJAY CHAUHAN</h5>
                        <h5>Type: SELL | REFURBISH</h5>
                        <h5>Submission Date: 12th Jan 2022</h5>
                        <h5>Original Amount: $200</h5>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col md="6">
                <b-card>
                    <b-card-header>
                        <h4 class="text-primary">Revivify Quotatoin</h4>
                    </b-card-header>
                    <hr>
                    <b-card-body>
                        <h5>Name: AJAY CHAUHAN</h5>
                        <h5>Type: SELL | REFURBISH</h5>
                        <h5>Quotation Date: 13th Jan 2022</h5>
                        <h5>Listing Amount: $100</h5>
                        <h5>Earning: $80</h5>
                    </b-card-body>
                    <b-card-footer>
                        <b-row>
                            <b-col md="6">
                            <div class="demo-inline-spacing">
                                <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                block
                                variant="success"
                                >
                                Accept
                                </b-button>
                            </div>
                            </b-col>

                            <b-col md="6">
                            <div class="demo-inline-spacing">
                                <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                block
                                variant="danger"
                                >
                                Reject
                                </b-button>
                            </div>
                            </b-col>
                        </b-row>
                    </b-card-footer>
                </b-card>
            </b-col>

        </b-row>
    </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardText,
  BButtonGroup,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import moment from "moment";
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BButtonGroup,
    BPagination,
    vSelect,
    moment,
  },
   directives: {
    Ripple,
  },
  data() {
    return {
      
    };
  },
  computed: {
    
  },
  methods: {
    
  },
};
</script>

